<script>
import UserService from "@/services/UserService";

export default {
    name: "VPictureUpload",
    props: {
        avatar: {
            type: String,
            description: "URL to an avatar",
            default: "",
        },
    },
    data() {
        return {
            loading: false,
            croppieImage: "",
            hasFile: false,
        };
    },
    methods: {
        handleCroppie(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            var reader = new FileReader();
            reader.onload = (e) => {
                this.$refs.croppieRef.bind({
                    url: e.target.result,
                });
            };

            reader.readAsDataURL(files[0]);
            this.hasFile = true;
        },
        async cropPicture() {
            this.loading = true;
            // Options can be updated.
            // Current option will return a base64 version of the uploaded image with a size of 600px X 450px.
            let options = {
                type: "blob",
                size: { width: 200, height: 200 },
                format: "png",
            };
            this.$refs.croppieRef.result(options, async (output) => {
                let formData = new FormData();
                formData.append("avatar", output);

                try {
                    const r = await UserService.POST_avatar(formData);
                    let user = r.data.data;
                    this.$emit("avatar", user.avatar);
                } catch (e) {
                    if ("errors" in e.response.data) {
                        this.$swal({
                            icon: "error",
                            title: this.$t("user.avatar_upload_error"),
                            text: e.response.data.errors.join(".\n"),
                        });
                    } else {
                        this.$swal({
                            icon: "error",
                            title: this.$t("user.avatar_upload_error"),
                            text: this.$t("unknown_error"),
                        });
                    }
                }
            });
            this.loading = false;
        },
        async deleteAvatar() {
            try {
                this.loading = true;
                const r = await UserService.DELETE_avatar();
                this.$emit("avatar", "");

            } catch (e) {
                if ("errors" in e.response.data) {
                    this.$swal({
                        icon: "error",
                        title: this.$t("user.avatar_delete_error"),
                        text: e.response.data.errors.join(".\n"),
                    });
                } else {
                    this.$swal({
                        icon: "error",
                        title: this.$t("user.avatar_delete_error"),
                        text: this.$t("unknown_error"),
                    });
                }
            }
            this.loading = false;
        },
    },
};
</script>

<template>
    <div class="v-picture-upload">
        <template v-if="avatar">
            <div class="row mt-4">
                <div class="col-12" v-if="loading">
                    <v-loader :loading="loading" />
                </div>
                <template v-else>
                    <div class="col-12 text-center">
                        <img :src="avatar" class="img-fluid" />
                    </div>
                    <div
                        class="col-12 col-md-6 offset-md-3 mt-4 v-picture-upload__button"
                    >
                        <button
                            class="btn btn-danger btn-block"
                            @click="deleteAvatar"
                        >
                            <i class="far fa-trash"></i>
                            {{ $t("user.delete_my_avatar") }}
                        </button>
                    </div>
                </template>
            </div>
        </template>
        <template v-else>
            <div class="row">
                <div class="col-12" v-if="loading">
                    <v-loader :loading="loading" />
                </div>
                <template v-else>
                    <div class="col-12">
                        <vue-croppie
                            ref="croppieRef"
                            :enableOrientation="true"
                            :enableResize="false"
                            :viewport="{
                                width: 200,
                                height: 200,
                                type: 'circle',                                
                            }"
                            :boundary="{ width: 300, height: 300 }"
                        >
                        </vue-croppie>
                    </div>

                    <div class="col-12 v-picture-upload__input">
                        <input
                            type="file"
                            @change="handleCroppie"
                            accept="image/*"
                        />
                    </div>
                    <div
                        class="col-12 col-md-6 offset-md-3 mt-4 v-picture-upload__button"
                    >
                        <button
                            class="btn btn-primary btn-block"
                            :disabled="!hasFile"
                            @click="cropPicture"
                        >
                            {{ $t("user.save_my_avatar") }}
                        </button>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<style lang="scss">
.v-picture-upload {
    &__input {
        text-align: center;
        overflow: hidden;
    }
    &__button {
    }
}
</style>