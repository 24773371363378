<script>
import UserService from "@/services/UserService";
import VPasswordScore from "@/components/VPasswordScore.vue";
import { zxcvbn } from "zxcvbn3";
import Cloner from "@/libraries/Cloner";

export default {
    name: "VUserPasswordForm",
    components: {
        VPasswordScore,
    },
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
        },
    },
    data() {
        return {
            editing: false,
            loading: false,
            roles: [],
            user: {
                id: null,
                name: null,
                email: null,
            },
            current_password: "",
            model: {
                password: "",
                confirm: "",
            },
            passwordScore: 0,
            passwordFeedback: null,
            notEqual: false,
            errors: null,
        };
    },
    watch: {
        model: {
            handler: function (n) {
                this.updateScore(n.password);
                if (
                    n.password.length > 0 &&
                    n.confirm.length > 0 &&
                    n.confirm == n.password
                ) {
                    this.notEqual = false;
                } else {
                    this.notEqual = true;
                }
            },
            deep: true,
        },
    },
    computed: {
        isValid: function () {
            if (
                this.model.password.length > 6 &&
                this.notEqual === false &&
                this.passwordScore == 4
            ) {
                return true;
            } else {
                return false;
            }
        },
    },

    created() {
        this.user = Cloner.pickClone(this.context, ["id", "name", "email"]);
    },
    methods: {
        /**
         * Save form
         */
        async saveForm() {
            try {
                const r = await UserService.changePassword({
                    current_password: this.current_password,
                    password: this.model.password,
                    confirm: this.model.confirm,
                });

                this.$notify({
                    message: this.$t("user.password_changed_notification"),
                    timeout: 5000,
                    icon: "far fa-check",
                    type: "success",
                });

                this.$emit("close");
            } catch (e) {
                this.errors = e.response.data.errors;
            }
        },
        updateScore(password) {
            let zx = zxcvbn(password);
            this.passwordScore = zx.score;
            this.passwordFeedback = zx.feedback;
        },
        rulePasswordsMatch(ctx) {
            if (ctx.value == this.model.password) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>

<template>
    <modal size="lg" :show="true" @close="$emit('close')" class="asset-modal">
        <template slot="header">
            {{ $t("user.change_password") }}
        </template>

        <template slot="default">
            <v-loader :loading="loading" v-if="loading" />
            <FormulateForm
                v-model="user"
                @submit="saveForm"
                :errors="errors"
                v-else
            >
                <div class="row">
                    <div class="col">
                        <FormulateInput
                            autocomplete="off"
                            type="password"
                            name="current_password"
                            v-model="current_password"
                            :label="$t('login.current_password')"
                            :placeholder="$t('login.current_password')"
                            validation="required"
                            :validation-name="$t('password.password')"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <FormulateInput
                            autocomplete="new-password"
                            type="password"
                            name="password"
                            v-model="model.password"
                            :label="$t('login.new_password')"
                            :placeholder="$t('login.password')"
                            validation="required"
                            :validation-name="$t('password.password')"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <FormulateInput
                            autocomplete="new-password"
                            type="password"
                            v-model="model.confirm"
                            name="confirm"
                            :label="$t('login.confirm_password')"
                            :placeholder="$t('login.confirm_password')"
                            validation="passwordsMatch"
                            :validation-name="$t('password.password')"
                            :validation-rules="{
                                passwordsMatch: rulePasswordsMatch,
                            }"
                            :validation-messages="{
                                passwordsMatch: $t(
                                    'password.passwords_do_not_match'
                                ),
                            }"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <v-password-score
                            :score="passwordScore"
                            :feedback="passwordFeedback"
                        />
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="col" v-if="editing">
                        <base-button type="warning" @click="resetPassword">{{
                            $t("users.button_reset_password")
                        }}</base-button>
                    </div>
                    <div class="col text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("user.change_password") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style>
</style>