<script>
import UserService from "@/services/UserService";
import MGConsts from "@/mixins/MGConsts";
import Cloner from "@/libraries/Cloner";

export default {
    name: "VUserMeForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
        },
    },
    mixins: [MGConsts],
    data() {
        return {
            editing: false,
            loading: false,
            errors: null,
            roles: [],
            user: {
                id: null,
                name: null,
                email: null,
            },
        };
    },
    created() {
        this.user = Cloner.pickClone(this.context, ["id", "name", "email"]);
    },
    methods: {
        /**
         * Save form
         */
        async saveForm() {
            try {
                const r = await UserService.PUT(this.user);
                await this.$store.dispatch("auth/refreshAuthUserData");
                this.$emit("close-refresh");
            } catch (e) {
                this.errors = e.response.data.errors;
            }
        },
    },
};
</script>

<template>
    <modal size="lg" :show="true" @close="$emit('close')" class="asset-modal">
        <template slot="header">
            {{ $t("user.edit_my_data") }}
        </template>

        <template slot="default">
            <v-loader :loading="loading" v-if="loading" />
            <FormulateForm
                v-model="user"
                @submit="saveForm"
                :errors="errors"
                v-else
            >
                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="name"
                            type="text"
                            :label="$t('users.name')"
                            :validation-name="$t('users.name')"
                            :placeholder="$t('users.name')"
                            validation="required"
                        />
                    </div>
                    <div class="col">
                        <FormulateInput
                            name="email"
                            type="email"
                            :label="$t('users.email')"
                            :validation-name="$t('users.email')"
                            :placeholder="$t('users.email')"
                            validation="required"
                            :disabled="true"
                        />
                    </div>
                </div>
                
                <div class="row mt-4">
                    <div class="col" v-if="editing">
                        <base-button type="warning" @click="resetPassword">{{
                            $t("users.button_reset_password")
                        }}</base-button>
                    </div>
                    <div class="col text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button                            
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("user.save") }}</base-button
                        >
                        
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style>
</style>